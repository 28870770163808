'use client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';

import CompanyLogo from '@bloom/library/components/CompanyLogo';
import Footer from '@bloom/library/components/Footer/Footer';
import { useAuthentication } from '@bloom/library/components/hooks/useAuthentication';
import { useMe } from '@bloom/library/components/hooks/useMe';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import CalendarIcon from '@bloom/library/components/Icon/Calendar';
import GuageIcon from '@bloom/library/components/Icon/Guage';
import OffIcon from '@bloom/library/components/Icon/Off';
import UserIcon from '@bloom/library/components/Icon/User';
import DotNav from '@bloom/library/components/Nav/DotNav';
import SocialLinks from '@bloom/library/components/Nav/SocialLinks';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import SimpleSlideshow from '@bloom/library/components/Slideshow/SimpleSlideshow';

import slide1 from '@bloom/portal/assets/images/tmp/slider1.png';
import { usePortfolio } from '@bloom/portal/components/hooks/usePortfolio';
import TopHalfMenu from '@bloom/portal/components/Nav/TopHalfMenu';

import About from './PhotographerProfile/About';
import Home from './PhotographerProfile/Home';
import Location from './PhotographerProfile/Location';
import Review from './PhotographerProfile/Review';

import style from './PhotographerProfile.module.scss';

const PhotographerProfile: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [searchParams] = useSearchParams();

  const [, { showQuoteRequestModal }] = useQuoteRequest();

  const mobileContentRef = useRef<HTMLDivElement>(null);

  const [slideshowHeight, setSlideShowHeight] = useState(0);

  const { me } = useMe();
  const { publicAccount } = usePublicAccountByCustomUrl();

  const websiteSettings = publicAccount?.settings.websiteSettings;
  const reviewsSectionOn = websiteSettings?.reviewsSectionOn;

  const { logout } = useAuthentication();

  const { data: portfolio } = usePortfolio(publicAccount?.user.id);
  const { albumArrangement, albums } = portfolio;

  const pages = useMemo(() => {
    const sortedAlbums = albums
      .slice()
      .sort((a, b) => albumArrangement[a.id] - albumArrangement[b.id]);

    return [
      {
        Comp: Home,
        // Is shown in desktop menu
        isDesktop: true,
        // Is shown in mobile menu
        isMobile: true,
        name: 'Home',
        path: '/',
      },
      {
        Comp: undefined,
        dropdownItems: sortedAlbums.map((album) => ({
          caption: album.title,
          onClick: () => router.push(`/portfolio?specialty=${encodeURIComponent(album.title)}`),
        })),
        isDesktop: true,
        isMobile: false,
        name: 'Gallery',
        path: '/portfolio',
      },
      {
        Comp: undefined,
        isDesktop: false,
        isMobile: true,
        name: 'Gallery',
        path: '/portfolio',
      },
      {
        Comp: About,
        isDesktop: false,
        isMobile: true,
        name: 'About',
        path: '/about',
      },
      ...(reviewsSectionOn
        ? [
            {
              Comp: Review,
              isDesktop: false,
              isMobile: true,
              name: 'Review',
              path: '/review',
            },
          ]
        : []),
      {
        Comp: Location,
        isDesktop: false,
        isMobile: true,
        name: 'Location',
        path: '/location',
      },
    ];
  }, [albumArrangement, albums, reviewsSectionOn]);

  const scrollToSection = useCallback(() => {
    const filteredPages = pages.filter((p) => p.isMobile);
    const index = filteredPages.findIndex((item) => item.path === pathname);

    if (
      window.innerWidth < 1024 &&
      mobileContentRef.current?.children.length &&
      Number.isInteger(index)
    ) {
      const element = mobileContentRef.current.children[index] as HTMLElement;

      // It it's a form page, e.g. /get-started,
      // do not scroll to the section.
      if (!element) {
        return;
      }

      const isIOS =
        /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/.test(
          navigator.userAgent
        ) && !window.MSStream;
      // needs another mobile device variable because of calculation difference between iOS and android
      const isAndroid = /(android)/i.test(navigator.userAgent);
      let scrollTo;

      if (isIOS || isAndroid) {
        // Tested in iPhone SE, iPhone 6, iPhone 7 simulators

        // That div needs for getting viewport height include iPhone
        // navbar. iOS safari ignores navbar and address bar in window.innerHeight
        // but counts them on getting scroll position
        // Although navbar height is fixed we calculate it because
        // in the future it might be changed with app design or its height
        // might be different depends on iOS version (I didn't test it in iOS <10)

        // 100vh = viewport + navbar height but it doesn't include address bar height;
        // useful to calculate navbar height.
        const div = document.createElement('div');
        document.body.appendChild(div);
        div.style.height = '100vh';

        let diff;

        const realScreenHeight =
          Math.abs(window.orientation) === 90
            ? window.screen.availWidth
            : window.screen.availHeight;

        // For some reason iOS Safari calculates scroll position in wrong way
        // if desired element height >= screen size (incudes navbar and addressbar
        // which is window.screen.availHeight) (iPhone SE Simulator case)
        if (isIOS) {
          if (element.offsetHeight < realScreenHeight) {
            diff = window.innerHeight - div.offsetHeight;
          } else {
            diff = window.innerHeight - realScreenHeight;
          }
        } else {
          // That math fixes scroll position issue on android devices
          diff = window.innerHeight - document.documentElement.clientHeight;
        }

        scrollTo = index === 0 ? 0 : element.offsetTop + window.innerHeight - 100 - diff;

        div.remove();
      } else {
        // calculate scroll position on not mobile devices
        // e.g. user decreased browser window
        scrollTo = index === 0 ? 0 : element.offsetTop + window.innerHeight - 100;
      }

      window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }
  }, [pathname, pages]);

  useEffect(() => {
    scrollToSection();

    // Due to browsers remember scroll position before refresh/reload a page
    // the only 1st time page scrolls to current section smoothly on mount component
    // other times on refresh/reload page in the same window
    // scroll always jumps to remembered position AFTER smooth scroll

    // prevent scroll jumps on reload page

    if ('scrollRestoration' in history) {
      try {
        history.scrollRestoration = 'manual';
      } catch (e) {
        // Prevent "Cannot set property scrollRestoration of [object History]
        // which has only a getter" error from happening in Samsung browser
        // on Android 7.0 and maybe in other browsers on other OSes.
      }
    }

    // Work around this issue
    // https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html
    if (window.innerWidth < 768) {
      setSlideShowHeight(window.innerHeight);
    }
  }, [scrollToSection]);

  const footerLinks = useMemo(
    () => [
      [
        {
          caption: 'Home',
          path: '',
        },
        {
          caption: 'About',
          path: '/about',
        },
        ...(reviewsSectionOn
          ? [
              {
                caption: 'Review',
                path: '/review',
              },
            ]
          : []),
        {
          caption: 'Location',
          path: '/location',
        },
      ],
    ],
    [reviewsSectionOn]
  );

  function showSection(path: string) {
    if (allowedToScrollRef.current) {
      const search = searchParams.toString();
      router.push(`${path}${search ? `?${search}` : ''}`);
    }
  }

  const allowedToScrollRef = useRef(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      allowedToScrollRef.current = true;
    }, 1100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  function handleScroll(e: React.WheelEvent<HTMLDivElement>) {
    if (e.deltaY < 0 || e.deltaY > 0) {
      const increment = e.deltaY > 0 ? 1 : -1;

      let paths = ['/', '/about', '/review', '/location'];
      if (!reviewsSectionOn) {
        paths = paths.filter((p) => p !== '/review');
      }
      const index = paths.indexOf(pathname);
      const nextPath = paths[index + increment];
      const { Comp, path = '/' } = pages.find((p) => p.path === nextPath) || {};
      if (Comp) {
        showSection(path);
      }
    }
  }

  function handleLoginClick() {
    router.push('/login');
  }

  let { buttonText } = publicAccount?.cta || {};
  // button text might be undefined by default,
  // or empty string if a customer cleaned up the field
  buttonText = buttonText || 'Get Started';

  const menuItems = useMemo(() => {
    return [
      me?.defaultAccountId
        ? {
            callback: () => {
              // Do not use router push to redirect to the dashboard.
              // We need to reload the app to reset the state and remove unwanted
              // elements such as the messenger widget on the dashboard page.
              window.location.href = `${window.location.origin}/portal/dashboard`;
            },
            caption: 'Dashboard',
            icon: <GuageIcon width={14} />,
          }
        : {
            callback: () => {
              window.location.href = `${window.location.origin}/portal/projects`;
            },
            caption: 'Bookings',
            icon: <CalendarIcon width={14} />,
          },
      {
        callback: () => {
          window.location.href = `${window.location.origin}/portal/user-profile`;
        },
        caption: 'User Profile',
        icon: <UserIcon width={14} />,
      },
      {
        callback: logout,
        caption: 'Log out',
        icon: <OffIcon width={12} />,
      },
    ];
  }, [logout, me?.defaultAccountId]);

  const [{ imageArrangement, images }] = publicAccount?.slideshowGallery?.albums || [
    { imageArrangement: {}, images: [] },
  ];
  const sortedImages = images
    .slice()
    .sort((a, b) => imageArrangement[a.id] - imageArrangement[b.id]);

  const slideshowImages = sortedImages.map((image) => image.source);

  if (publicAccount) {
    return (
      <div className={style.container}>
        <TopHalfMenu
          ctaButtonText={buttonText}
          items={pages}
          logo={publicAccount.logo}
          logoClass={style.logoMobileClass}
          logoDark={publicAccount.logoDark}
          onLoginClick={handleLoginClick}
          onRequestQuoteClick={showQuoteRequestModal}
          pathname={pathname}
          userMenuItems={menuItems}
        />
        <Link href="/">
          <CompanyLogo
            className={style.logo}
            isShort
            src={publicAccount.logoDark || publicAccount.logo}
          />
        </Link>
        <SocialLinks
          className={twMerge(style.social, style.heroSocial)}
          links={publicAccount.socialMediaLinks}
        />
        <div
          className={style.slideshow}
          style={slideshowHeight ? { height: slideshowHeight } : undefined}
        >
          <SimpleSlideshow slides={slideshowImages.length > 0 ? slideshowImages : [slide1.src]} />
        </div>

        <div className={style.desktopContent} data-testid="desktop-ui" onWheel={handleScroll}>
          {children}
          {/* <Routes>
            {reviewsSectionOn && <Route element={<Review />} path="/review" />}
            <Route element={<Location />} path="/location" />
          </Routes> */}

          {pathname !== '/location' ? (
            <DotNav
              className={style.dotNav}
              items={pages
                .filter(({ Comp }) => typeof Comp !== 'undefined')
                .map(({ name, path }) => ({ name, path }))}
            />
          ) : null}
        </div>
        <div className={style.mobileContent} ref={mobileContentRef}>
          {pages.map(({ Comp }, index) => (Comp ? <Comp isMobile key={index} /> : null))}
        </div>
        <Footer
          className={style.footer}
          companyLogo={publicAccount.logoDark}
          currentPath={pathname}
          links={footerLinks}
          socialLinks={publicAccount.socialMediaLinks}
        />
      </div>
    );
  }

  return null;
};

export default PhotographerProfile;
