__turbopack_context__.v({
  "container": "PhotographerProfile-module-scss-module__sF7r0W__container",
  "desktopContent": "PhotographerProfile-module-scss-module__sF7r0W__desktopContent",
  "dotNav": "PhotographerProfile-module-scss-module__sF7r0W__dotNav",
  "footer": "PhotographerProfile-module-scss-module__sF7r0W__footer",
  "heroSocial": "PhotographerProfile-module-scss-module__sF7r0W__heroSocial",
  "logo": "PhotographerProfile-module-scss-module__sF7r0W__logo",
  "logoMobileClass": "PhotographerProfile-module-scss-module__sF7r0W__logoMobileClass",
  "mobileContent": "PhotographerProfile-module-scss-module__sF7r0W__mobileContent",
  "slideshow": "PhotographerProfile-module-scss-module__sF7r0W__slideshow",
  "social": "PhotographerProfile-module-scss-module__sF7r0W__social",
});
