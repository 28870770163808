'use client';
import React, { Component } from 'react';

import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { CSSTransition } from '@bloom/ui/components/CSSTransition';
import { ChevronIcon } from '@bloom/ui/components/Icons/Chevron';

import Button from '@bloom/library/components/Button/Button';
import SecondaryButton from '@bloom/library/components/Button/SecondaryButton';
import CompanyLogo from '@bloom/library/components/CompanyLogo';
import {
  DropdownTrigger,
  DropdownMenu,
  DropdownMenuItem,
  Dropdown,
} from '@bloom/library/components/Floating/Dropdown';
import withCustomColor from '@bloom/library/components/HOC/withCustomColor';
import { useImageFallback } from '@bloom/library/components/hooks/useImageFallback';
import { useMe } from '@bloom/library/components/hooks/useMe';
import TriangleIcon from '@bloom/library/components/Icon/Triangle';
import AvatarDropdown from '@bloom/library/components/Nav/AvatarDropdown';
import { getCookie } from '@bloom/library/utils/browser';

import { FormButtonWrapper } from '@bloom/portal/containers/public/pages/templates/verticalSplit/FormButton';

import style from './TopHalfMenu.module.scss';

// in ms
const animationDuration = 600;

const CompanyLogoWrapper: React.FC<{ className?: string; source: string }> = (props) => {
  const { className, source } = props;
  const { src } = useImageFallback(source, 'XS');
  return <CompanyLogo className={twMerge(style.userLogoDark, className)} isShort src={src} />;
};

const AvatarDropdownWrapper: React.FC<{ userMenuItems: Array<unknown> }> = (props) => {
  const { userMenuItems } = props;
  const { me } = useMe();

  if (me) {
    return (
      <AvatarDropdown
        className={style.avatar}
        isWelcomeShown={false}
        menuItems={userMenuItems}
        placement="bottom-end"
        user={me}
      />
    );
  }

  return null;
};

interface Props {
  customColor: string;
  items: Array<unknown>;
  logo?: string;
  logoClass?: string;
  logoDark?: string;
  onLoginClick: VoidFunction;
  pathname: string;
  userMenuItems: Array<unknown>;
}

interface State {
  isAninationRunning: boolean;
  isDetached: boolean;
  isMobileMenuOpen: boolean;
  isPortalMenuShown: boolean;
}

class TopHalfMenu extends Component<Props, State> {
  static defaultProps = {
    logo: '',
    logoClass: '',
    logoDark: '',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isAninationRunning: false,
      isDetached: false,
      isMobileMenuOpen: false,
      isPortalMenuShown: false,
    };
  }

  componentDidMount() {
    // Note that using _isMounted is anti-pattern.
    // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
    // I use it, because I haven't found a better solution.
    this._isMounted = true;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('load', this.handleScroll);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('load', this.handleScroll);
  }

  toggleHamburgerMenu = () => {
    const { isAninationRunning, isMobileMenuOpen } = this.state;
    if (!isAninationRunning) {
      this.setState({
        isAninationRunning: true,
        isMobileMenuOpen: !isMobileMenuOpen,
      });
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ isAninationRunning: false });
        }
      }, animationDuration);
    }
  };

  togglePortalMenu = (e) => {
    e.preventDefault();
    const { isPortalMenuShown } = this.state;
    this.setState({ isPortalMenuShown: !isPortalMenuShown });
  };

  handleScroll = () => {
    const { pageYOffset } = window;
    const { isDetached } = this.state;
    if (pageYOffset < 3) {
      this.setState({ isDetached: false });
    } else if (pageYOffset >= 3 && !isDetached) {
      this.setState({ isDetached: true });
    }
  };

  handleLoginClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.onLoginClick();
    const { isMobileMenuOpen } = this.state;
    if (isMobileMenuOpen) {
      setTimeout(() => this.setState({ isMobileMenuOpen: false }), 300);
    }
  };

  handleUserMobileMenuClick = (cb) => {
    this.setState({ isMobileMenuOpen: false });
    setTimeout(cb, 300);
  };

  handleOutsideClick = (e) => {
    if (e.target === this.mobileMenuWrapper) {
      this.toggleHamburgerMenu();
    }
  };

  renderMobileMenu = () => {
    const { customColor, items, pathname, userMenuItems } = this.props;
    const { isPortalMenuShown } = this.state;
    const isLoggedIn = !!getCookie('bloom_token');

    return (
      <div
        className={style.mobileMenuWrapper}
        onClick={this.handleOutsideClick}
        ref={(node) => (this.mobileMenuWrapper = node)}
      >
        <nav className={style.mobileMenu}>
          <ul>
            {items.map(({ isMobile, name, path }, index) => {
              if (!isMobile) {
                return null;
              }
              return (
                <li key={index}>
                  <Link
                    className={twMerge(style.link, path === pathname ? style.selected : '')}
                    href={path}
                    onClick={this.toggleHamburgerMenu}
                  >
                    {name}
                    <i style={{ borderColor: customColor }} />
                  </Link>
                </li>
              );
            })}
            {!isLoggedIn && (
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={style.button} href="#" onClick={this.handleLoginClick}>
                  Login
                </a>
              </li>
            )}

            {isLoggedIn && (
              <li className={style.lastItem}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={twMerge(style.button, style.portalButton)}
                  href="#"
                  onClick={this.togglePortalMenu}
                >
                  My Portal <TriangleIcon rotate={isPortalMenuShown ? null : 180} />
                </a>
                {isPortalMenuShown && (
                  <ul className={style.userMenu}>
                    {userMenuItems.map((item, index) => (
                      <li key={index}>
                        <span onClick={this.handleUserMobileMenuClick.bind(null, item.callback)}>
                          {item.caption}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  };

  render() {
    const { customColor, items, logo, logoClass, logoDark, pathname, userMenuItems } = this.props;

    const { isDetached, isMobileMenuOpen } = this.state;
    const isLoggedIn = !!getCookie('bloom_token');

    return (
      <header
        className={twMerge(
          style.menuContainer,
          // This is design requirement. The underline should be
          // visible only on one page on iPad landscape.
          pathname === '/location' ? style.location : '',
          isDetached ? style.detached : ''
        )}
        data-testid="vertical-split-top-menu"
      >
        <nav className={style.desktopMenu}>
          {items.map(({ dropdownItems, isDesktop, name, onClick, path }, index) => {
            if (!isDesktop) {
              return null;
            }
            if (dropdownItems && dropdownItems[0]) {
              return (
                <span className={style.dropdown} key={index}>
                  <Dropdown openOnHover placement="bottom-end">
                    <DropdownTrigger asChild>
                      <Button
                        className="hidden cursor-pointer items-center gap-2 whitespace-nowrap text-xs sm:inline-flex"
                        onClick={dropdownItems[0]?.onClick}
                      >
                        Gallery <ChevronIcon />
                      </Button>
                    </DropdownTrigger>

                    <DropdownMenu
                      className={twMerge(style.dropdownMenu, 'flex flex-col')}
                      data-testid="sort-dropdown-menu"
                    >
                      {dropdownItems.map(({ caption, onClick }) => (
                        <DropdownMenuItem
                          className="flex items-center justify-between text-xs"
                          key={`${caption}`}
                          onItemClick={onClick}
                        >
                          {caption}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </span>
              );
            }
            if (typeof onClick === 'function') {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className={style.link} href="#" key={index} onClick={onClick}>
                  {name}
                  <i style={{ borderColor: customColor }} />
                </a>
              );
            }

            return (
              <Link
                className={twMerge(style.link, path === pathname ? style.selected : '')}
                href={path}
                key={index}
              >
                {name}
                <i style={{ borderColor: customColor }} />
              </Link>
            );
          })}
          <div className={style.buttons}>
            {!isLoggedIn && (
              <SecondaryButton className={style.login} onClick={this.handleLoginClick}>
                Login
              </SecondaryButton>
            )}

            <FormButtonWrapper />

            {isLoggedIn && <AvatarDropdownWrapper userMenuItems={userMenuItems} />}
          </div>
        </nav>
        <div className={style.logoContainer}>
          <Link href="/">
            <CompanyLogo className={twMerge(style.userLogo, logoClass)} isShort src={logo} />
          </Link>
          <Link href="/">
            <CompanyLogoWrapper className={logoClass} source={logoDark || logo} />
          </Link>
          <div
            className={twMerge(style.hamburgerButton, isMobileMenuOpen ? style.active : '')}
            onClick={this.toggleHamburgerMenu}
          >
            <span className={style.topBar} />
            <span className={style.middleBar} />
            <span className={style.bottomBar} />
          </div>
        </div>
        <CSSTransition active={isMobileMenuOpen}>{this.renderMobileMenu()}</CSSTransition>
      </header>
    );
  }
}

export default withCustomColor(TopHalfMenu);
