//
import React from 'react';

function Guage({ width = 13, className = '', color = '#000' }) {
  return (
    <svg width={width} height={(width * 18) / 17} className={className} viewBox="1 0 17 18">
      <path
        fill={color}
        d="M9.5,0 C4.80538462,0 1,3.80538462 1,8.5 C1,13.1946154 4.80538462,17 9.5,17 C14.1946154,17 18,13.1946154 18,8.5 C18,3.80538462 14.1946154,0 9.5,0 Z M4.93518519,8.34259259 L3.67592593,8.34259259 C3.67592593,5.21333333 6.21333333,2.67592593 9.34259259,2.67592593 L9.34259259,3.93518519 C6.90844444,3.93518519 4.93518519,5.90844444 4.93518519,8.34259259 Z M9.5,13.2222222 C8.23595806,13.2222222 7.32844077,12.132963 7.69201136,11.0512593 L9.5,5.66666667 L11.3079886,11.0512593 C11.6715592,12.132963 10.7640419,13.2222222 9.5,13.2222222 Z"
        stroke="none"
        fillRule="evenodd"
      />
    </svg>
  );
}
export default Guage;
