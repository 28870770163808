import React from 'react';

function Triangle({ width = 7, className = '', color = 'currentColor', rotate = 0 }) {
  return (
    <svg
      className={className}
      height={width}
      transform={`rotate(${rotate || 0})`}
      viewBox="0 0 7 7"
      width={width}
    >
      <polygon fill={color} points="7 5.5 0 5.5 3.5 1.5 7 5.5" />
    </svg>
  );
}

export default Triangle;
