__turbopack_context__.v({
  "active": "TopMenu-module-scss-module__3v0C0a__active",
  "avatar": "TopMenu-module-scss-module__3v0C0a__avatar",
  "bottomBar": "TopMenu-module-scss-module__3v0C0a__bottomBar",
  "button": "TopMenu-module-scss-module__3v0C0a__button",
  "buttons": "TopMenu-module-scss-module__3v0C0a__buttons",
  "desktopMenu": "TopMenu-module-scss-module__3v0C0a__desktopMenu",
  "detached": "TopMenu-module-scss-module__3v0C0a__detached",
  "dropdown": "TopMenu-module-scss-module__3v0C0a__dropdown",
  "dropdownMenu": "TopMenu-module-scss-module__3v0C0a__dropdownMenu",
  "hamburgerButton": "TopMenu-module-scss-module__3v0C0a__hamburgerButton",
  "lastItem": "TopMenu-module-scss-module__3v0C0a__lastItem",
  "link": "TopMenu-module-scss-module__3v0C0a__link",
  "location": "TopMenu-module-scss-module__3v0C0a__location",
  "login": "TopMenu-module-scss-module__3v0C0a__login",
  "menuContainer": "TopMenu-module-scss-module__3v0C0a__menuContainer",
  "middleBar": "TopMenu-module-scss-module__3v0C0a__middleBar",
  "minimalistTemplate": "TopMenu-module-scss-module__3v0C0a__minimalistTemplate",
  "mobileMenu": "TopMenu-module-scss-module__3v0C0a__mobileMenu",
  "mobileMenuWrapper": "TopMenu-module-scss-module__3v0C0a__mobileMenuWrapper",
  "mobileSubMenu": "TopMenu-module-scss-module__3v0C0a__mobileSubMenu",
  "popup": "TopMenu-module-scss-module__3v0C0a__popup",
  "portalButton": "TopMenu-module-scss-module__3v0C0a__portalButton",
  "selected": "TopMenu-module-scss-module__3v0C0a__selected",
  "textLogo": "TopMenu-module-scss-module__3v0C0a__textLogo",
  "topBar": "TopMenu-module-scss-module__3v0C0a__topBar",
  "userLogo": "TopMenu-module-scss-module__3v0C0a__userLogo",
  "userMenu": "TopMenu-module-scss-module__3v0C0a__userMenu",
});
