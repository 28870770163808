__turbopack_context__.v({
  "dark": "AvatarDropdown-module-scss-module__D9rEEW__dark",
  "hasUpdates": "AvatarDropdown-module-scss-module__D9rEEW__hasUpdates",
  "user": "AvatarDropdown-module-scss-module__D9rEEW__user",
  "userAvatar": "AvatarDropdown-module-scss-module__D9rEEW__userAvatar",
  "userHead": "AvatarDropdown-module-scss-module__D9rEEW__userHead",
  "userIcon": "AvatarDropdown-module-scss-module__D9rEEW__userIcon",
  "userItem": "AvatarDropdown-module-scss-module__D9rEEW__userItem",
  "userMenu": "AvatarDropdown-module-scss-module__D9rEEW__userMenu",
  "userName": "AvatarDropdown-module-scss-module__D9rEEW__userName",
  "userTitle": "AvatarDropdown-module-scss-module__D9rEEW__userTitle",
  "userTriangle": "AvatarDropdown-module-scss-module__D9rEEW__userTriangle",
});
