'use client';

import React, { useCallback, useEffect } from 'react';

import { useAccount } from '@bloom/library/components/hooks/useAccounts';
import { useFetch } from '@bloom/library/components/hooks/useFetch';
import { useLoaderPublicAccount } from '@bloom/library/components/hooks/usePublicAccount';

const WidgetMessenger: React.FC = () => {
  const { account } = useAccount();
  const publicAccount = useLoaderPublicAccount();
  const { post } = useFetch();
  // Note, during SSR profile.settings is undefined.
  const { embedWidget = { autoPopup: false, isLive: false } } = publicAccount?.settings || {};

  const savePageView = useCallback(() => {
    if (account?.id !== publicAccount?.id) {
      post('/api/page-views', { profileId: publicAccount?.id });
    }
  }, [account?.id, post, publicAccount?.id]);

  useEffect(() => {
    savePageView();
  }, [savePageView]);

  useEffect(() => {
    if (publicAccount && embedWidget.isLive && !window.bloomSettings) {
      window.bloomSettings = { profileId: publicAccount.id, userId: publicAccount.user.id };

      const host = `${process.env.BLOOM_WIDGET_SUBDOMAIN}.${process.env.BLOOM_DOMAIN}`;

      const bloomScript = document.createElement('script');
      bloomScript.src = `https://${host}/widget.js?v=${process.env.BLOOM_BUILD_ID}`;

      bloomScript.type = 'module';
      document.head.appendChild(bloomScript);
    }
  }, [embedWidget.isLive, publicAccount]);

  return null;
};

export { WidgetMessenger };
