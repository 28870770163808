import React, { useMemo } from 'react';

import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { PoweredBy } from '@bloom/ui/components/PoweredBy';

import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import SocialLinks from '@bloom/library/components/Nav/SocialLinks';
import { renderTiptapToHtml } from '@bloom/library/components/Tiptap/template.server';
import { getBusinessNameFromAccount } from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './FooterWithContent.module.scss';

interface IProps {
  className?: string;
}

const Footer: React.FC<IProps> = (props) => {
  const { className } = props;

  const activeTemplate = useActiveTemplate();

  const { publicAccount } = usePublicAccountByCustomUrl();

  const { address } = publicAccount?.location || {};
  const heading = getBusinessNameFromAccount(publicAccount);
  const footerText = publicAccount?.footerText || '';

  const renderedFooter = useMemo(() => {
    try {
      const renderedBody = renderTiptapToHtml(footerText);
      return renderedBody;
    } catch {}

    return typeof footerText === 'string' ? footerText : '';
  }, [footerText]);

  if (publicAccount) {
    return (
      <footer
        className={twMerge(
          style.footer,
          activeTemplate === 'nordic-air' ? style.nordicAirTemplate : '',
          className
        )}
      >
        <div className={style.container}>
          <section className={style.mainSection}>
            <h3
              className={style.sectionHeader}
              dangerouslySetInnerHTML={{ __html: escapeHTML(heading) }}
            />
            <p className={style.sectionText} dangerouslySetInnerHTML={{ __html: renderedFooter }} />
          </section>

          {address && (
            <section className={style.secondarySection}>
              <h3 className={style.sectionHeader}>Studio</h3>
              <div className={style.studioContacts}>
                <p className={style.sectionText}>
                  {address.street}
                  <br />
                  {address.city}
                  <br />
                  {address.state} {address.zip || address.postalCode}
                </p>
                {publicAccount.phone ? (
                  <p className={twMerge(style.sectionText, style.phone)}>{publicAccount.phone}</p>
                ) : null}
              </div>
            </section>
          )}

          <section className={twMerge(style.secondarySection, style.socialSection)}>
            <h3
              className={twMerge(style.sectionHeader, style.hideMobile, style.socialSectionHeader)}
            >
              Follow
            </h3>
            <SocialLinks
              className={style.footerSocialLinks}
              links={publicAccount.socialMediaLinks}
            />
            <SocialLinks
              className={style.socialLinksTwoRows}
              links={publicAccount.socialMediaLinks}
              showInTwoRows
            />
          </section>
        </div>

        <div className={style.legal}>
          <section className={style.mainSection}>
            <PoweredBy isBrandingRemoved={publicAccount.isBrandingRemoved} utm_medium="website" />
          </section>

          <section className={style.secondarySection}>
            <nav>
              <Link className={style.footerMuted} href="/legal/client-terms">
                Terms
              </Link>
              <Link className={style.footerMuted} href="/legal/privacy">
                Privacy
              </Link>
            </nav>

            <div className={twMerge(style.copyright, style.footerMuted)}>
              <span>
                <span className={activeTemplate !== 'nordic-air' ? style.hideMobile : ''}>
                  Copyright{' '}
                </span>
                &copy; <span className={style.copyrightYear}>{new Date().getFullYear()}</span>
              </span>
            </div>
          </section>

          {address && <section className={twMerge(style.secondarySection, style.hideMobile)} />}
        </div>
      </footer>
    );
  }

  return null;
};

export default Footer;
