'use client';

import React, { useEffect, useMemo } from 'react';

import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';
import { PersistStateKeyEnum } from '@bloom/library/constants/persist-state-keys';
import { setCookie } from '@bloom/library/utils/browser';

import AsyncMinimalist from '@bloom/portal/containers/public/pages/templates/minimalist/PhotographerProfile';
import AsyncNordic from '@bloom/portal/containers/public/pages/templates/nordicAir/PhotographerProfile';
import AsyncVerticalSplit from '@bloom/portal/containers/public/pages/templates/verticalSplit/Container';

import { WidgetMessenger } from './WidgetMessenger';

const TemplateWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const activeTemplate = useActiveTemplate();

  useEffect(() => {
    switch (activeTemplate) {
      case 'nordic-air': {
        document.body.style.setProperty('--font-regular', 'Raleway');
        document.body.style.setProperty('--font-medium', 'Raleway Medium');
        break;
      }
      case 'minimalist': {
        document.body.style.setProperty('--font-regular', 'Roboto Condensed');
        document.body.style.setProperty('--font-medium', 'Roboto Condensed Bold');
        break;
      }
      case 'vertical-split': {
        document.body.style.setProperty(
          '--font-regular',
          "'Gotham Rounded Book', Helvetica, sans-serif"
        );
        document.body.style.setProperty('--font-medium', 'Gotham Rounded Medium');
        break;
      }
      default:
        break;
    }
  }, [activeTemplate]);

  useEffect(() => {
    const matches = window.location.search.match(/utm_[^=]+=[^&]+/g);
    if (matches) {
      matches.forEach((match) => {
        const [key, value] = match.split('=');
        if (key && value) {
          setCookie(key, value);
        }
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(PersistStateKeyEnum.WIDGET_AUTO_OPEN_CHAT, 'false');
    localStorage.setItem(PersistStateKeyEnum.WIDGET_BUTTON_TEXT_EXCEPTION, 'true');
    return () => {
      localStorage.removeItem(PersistStateKeyEnum.WIDGET_AUTO_OPEN_CHAT);
      localStorage.removeItem(PersistStateKeyEnum.WIDGET_BUTTON_TEXT_EXCEPTION);
    };
  }, []);

  const Wrapper = useMemo(() => {
    switch (activeTemplate) {
      case 'nordic-air':
        return AsyncNordic;
      case 'minimalist':
        return AsyncMinimalist;
      case 'vertical-split':
        return AsyncVerticalSplit;
      default:
        return null;
    }
  }, [activeTemplate]);

  if (Wrapper) {
    return (
      <>
        <Wrapper>{children}</Wrapper>

        <WidgetMessenger />
      </>
    );
  }
  return null;
};

export { TemplateWrapper };
