'use client';

import React, { useEffect, useCallback, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import disableImageRightClick from '@bloom/library/components/HOC/disableImageRightClick';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { isHighDensityDisplay } from '@bloom/library/utils/browser';
import { getSizeSuffix } from '@bloom/library/utils/image';

import style from './SimpleSlideshow.module.scss';

import { useImageFallback } from '../hooks/useImageFallback';

interface IProps {
  onContextMenu: () => void;
  slides?: Array<string>;
  // Duration of tick in ms.
  tickDuration?: number;
  // Ticks per slide.
  tickLimit?: number;
}

const SimpleSlideshow: React.FC<IProps> = (props) => {
  const { onContextMenu, slides, tickDuration = 1000, tickLimit = 5 } = props;

  const [prevSlideIndex, setPrevSlideIndex] = useState(slides.length > 1 ? slides.length - 1 : -1);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [tick, setTick] = useState(0);

  const slideShowRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const widthRef = useRef(0);

  useImageFallback(slides[activeSlideIndex - 1], getSizeSuffix(Math.max(widthRef.current, 800)));
  const { src: highRezSrc, status: highRezStatus } = useImageFallback(
    slides[activeSlideIndex],
    getSizeSuffix(Math.max(widthRef.current, 800))
  );
  const { src: lowRezSrc } = useImageFallback(slides[activeSlideIndex], 'XS');

  useImageFallback(slides[activeSlideIndex + 1], getSizeSuffix(Math.max(widthRef.current, 800)));

  const handleSlideChange = useCallback(() => {
    const slidesCount = slides.length;

    // Change slides only when there're at least two of them.
    if (slidesCount < 2) {
      return;
    }

    const nextIndex = (activeSlideIndex + 1) % slidesCount;
    // Don't show next slide until it's loaded.
    if (tick >= tickLimit) {
      setTick(0);
      setPrevSlideIndex(activeSlideIndex);
      setActiveSlideIndex(nextIndex);
    } else {
      setTick(tick + 1);
    }
  }, [activeSlideIndex, slides.length, tick, tickLimit]);

  useEffect(() => {
    if (slideShowRef.current) {
      const { width } = slideShowRef.current.getBoundingClientRect();
      // API expects width to be integer.
      widthRef.current = Math.ceil(isHighDensityDisplay() ? 2 * width : 1.3 * width);

      intervalRef.current = setInterval(() => handleSlideChange(), tickDuration);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [handleSlideChange, tickDuration]);

  return (
    <div className={style.slideshow} ref={slideShowRef}>
      {slides.map((src, index) => (
        <div
          className={twMerge(
            style.slide,
            index === activeSlideIndex ? style.fadeIn : '',
            index === prevSlideIndex ? style.fadeOut : ''
          )}
          key={index}
          onContextMenu={onContextMenu}
          role="presentation"
        >
          {/*
              Use progressive image loading for the first image to improve UX.
              Other images are shown only after they're loaded.
            */}
          {index === 0 && <div className={style.lowRez} style={{ backgroundImage: lowRezSrc }} />}
          <div
            className={style.highRez}
            style={{
              backgroundImage: `url('${highRezSrc}')`,
              opacity: highRezStatus === AsyncStatusEnum.SUCCESS ? 1 : 0,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default disableImageRightClick(SimpleSlideshow);
