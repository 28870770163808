import React from 'react';

import { Placement } from '@floating-ui/react';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { Popover, PopoverContent, PopoverTrigger } from '@bloom/ui/components/Popover';
import { UserPic, UserPicSizeEnum } from '@bloom/ui/components/UserPic';

import MenuItem from '@bloom/library/components/Dropdown/MenuItem';
import TriangleIcon from '@bloom/library/components/Icon/Triangle';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './AvatarDropdown.module.scss';

interface IProps {
  avatarClassName?: string;
  className?: string;
  hasUpdates?: boolean;
  isWelcomeShown?: boolean;
  menuItems: Array<{ callback: () => void; caption: string; icon: React.ReactNode; path: string }>;
  placement?: Placement;
  type?: 'light' | 'dark';
  user: Record<'avatar' | 'firstName' | 'lastName', string>;
}

const AvatarDropdown: React.FC<IProps> = (props) => {
  const {
    avatarClassName,
    className,
    hasUpdates = false,
    isWelcomeShown = true,
    menuItems,
    placement = 'bottom',
    type = 'light',
    user,
  } = props;

  function renderItemCaption(item) {
    return (
      <div className={style.userItem}>
        <div className={style.userIcon}>{item.icon}</div>
        <div className={style.userTitle}>{item.caption}</div>
      </div>
    );
  }

  function renderLink(item, index) {
    if (item.path.includes('//')) {
      return (
        <a className={style.userMenu} href={item.path} key={index}>
          {renderItemCaption(item)}
        </a>
      );
    }

    return (
      <Link className={style.userMenu} href={item.path} key={index}>
        {renderItemCaption(item)}
      </Link>
    );
  }

  return (
    <Popover placement={placement}>
      <PopoverTrigger asChild>
        <div className={twMerge(style.user, type === 'dark' ? style.dark : '', className)}>
          {isWelcomeShown && (
            <div
              className={style.userName}
              dangerouslySetInnerHTML={{ __html: escapeHTML(`Hey, ${user.firstName}`) }}
            />
          )}
          <div className={style.userHead}>
            <UserPic
              className={twMerge(
                style.userAvatar,
                hasUpdates ? style.hasUpdates : '',
                avatarClassName
              )}
              initials={`${user.firstName} ${user.lastName}`}
              size={UserPicSizeEnum.MEDIUM}
              src={user.avatar}
            />
            <TriangleIcon className={style.userTriangle} rotate={180} width={7} />
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-48 !rounded-none !p-0">
        {menuItems.map((item, index) =>
          item.path ? (
            renderLink(item, index)
          ) : (
            <MenuItem
              caption={renderItemCaption(item)}
              className={style.userMenu}
              key={index}
              onClick={item.callback}
            />
          )
        )}
      </PopoverContent>
    </Popover>
  );
};

export default AvatarDropdown;
