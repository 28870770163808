import React, { Component } from 'react';
import { twMerge } from 'tailwind-merge';
import Link from 'next/link';
import PropTypes from 'prop-types';

import CompanyLogo from '@bloom/library/components/CompanyLogo';
import StyledLink from '@bloom/library/components/Link/StyledLink';
import SocialLinks from '@bloom/library/components/Nav/SocialLinks';

import style from './Footer.module.scss';

export default class Footer extends Component {
  static propTypes = {
    links: PropTypes.array.isRequired,
    currentPath: PropTypes.string.isRequired,
    companyLogo: PropTypes.string,
    socialLinks: PropTypes.array,
    className: PropTypes.string,
  };

  static defaultProps = {
    companyLogo: null,
    socialLinks: [],
  };

  render() {
    const { links, currentPath, className, companyLogo, socialLinks } = this.props;

    return (
      <footer className={twMerge(style.footer, className)}>
        <div className={style.content}>
          <div className={style.logo}>
            <Link href="/">
              <CompanyLogo className={style.logo} src={companyLogo} />
            </Link>
          </div>
          <div className={style.social}>
            <SocialLinks links={socialLinks} />
          </div>
          <nav>
            {links.map((chunk, i) => (
              <div className={style.linkContainer} key={i}>
                {chunk.map(({ path, caption }, j) => (
                  <span key={j}>
                    <StyledLink href={path} isActive={path === currentPath}>
                      {caption}
                    </StyledLink>
                  </span>
                ))}
              </div>
            ))}
          </nav>
        </div>
        <div className={style.delimiter} />
        <div className={style.legal}>
          <div className={style.copyright}>
            <span>&copy; {new Date().getFullYear()} Bloom</span>
          </div>
          <nav>
            <Link href="/legal/client-terms">Terms</Link>
            <Link href="/legal/privacy">Privacy</Link>
          </nav>
        </div>
      </footer>
    );
  }
}
