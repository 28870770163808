import React from 'react';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import style from './DotNav.module.scss';

interface IProps {
  className?: string;
  items: Array<{ name: string; path: string }>;
}

const DotNav: React.FC<IProps> = (props) => {
  const { className, items = emptyArray } = props;

  const pathname = usePathname();
  const [searchParams] = useSearchParams();
  const search = searchParams.toString();

  return (
    <div className={twMerge(style.container, className)}>
      {items.map(({ name, path }) => (
        <Link
          className={twMerge(style.dot, path === pathname ? style.active : '')}
          data-testid={`${path.replace('/', '')}-dot-link`}
          href={`${path}${search ? `?${search}` : ''}`}
          key={path}
        >
          <span className={style.caption}>{name}</span>
          <i />
        </Link>
      ))}
    </div>
  );
};

export default DotNav;
