'use client';

import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Ellipsis extends Component {
  static propTypes = {
    children: PropTypes.string,
    dangerouslySetInnerHTML: PropTypes.object,
    ellipsis: PropTypes.string,
    linesMax: PropTypes.number,
    tag: PropTypes.string,
  };

  static defaultProps = {
    ellipsis: '...',
    linesMax: 0,
    tag: 'span',
  };

  constructor(props) {
    super(props);
    const { children, dangerouslySetInnerHTML } = props;
    let text = '';
    if (children !== undefined) {
      text = children;
    } else if (dangerouslySetInnerHTML) {
      text = dangerouslySetInnerHTML.__html;
    }
    this.state = {
      isOverflown: false,
      calculatedText: text,
    };
  }

  componentDidMount() {
    this.calculateEllipsis();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { children, dangerouslySetInnerHTML, ellipsis, linesMax, tag } = this.props;
    const { calculatedText, isOverflown } = this.state;

    return (
      nextProps.children !== children ||
      nextProps.dangerouslySetInnerHTML !== dangerouslySetInnerHTML ||
      nextProps.linesMax !== linesMax ||
      nextProps.tag !== tag ||
      nextProps.ellipsis !== ellipsis ||
      nextState.isOverflown !== isOverflown ||
      nextState.calculatedText !== calculatedText
    );
  }

  componentDidUpdate() {
    this.calculateEllipsis();
  }

  getText() {
    const { children, dangerouslySetInnerHTML } = this.props;
    let text;
    if (this.isHtml()) {
      text = dangerouslySetInnerHTML.__html;
    } else {
      text = children;
    }
    return text;
  }

  isHtml() {
    return this.props.children === undefined;
  }

  isOverflown() {
    const { linesMax } = this.props;
    const computedStyle = document.defaultView.getComputedStyle(this.el, null);
    const lineHeight = computedStyle.getPropertyValue('line-height').replace('px', '') - 0;
    const { height } = this.el.getBoundingClientRect();
    const lines = height / lineHeight;

    return height !== 0 && lines > linesMax;
  }

  findClosestSpace(str, i) {
    if (str[i] === ' ') {
      return i;
    }
    // search forward
    let next = str.indexOf(' ', i);
    let prev = str.lastIndexOf(' ', i);
    const max = str.length - 1;
    if (next === -1) {
      next = max;
    }
    if (prev === -1) {
      prev = 0;
    }
    return next - i <= prev - i ? next : prev;
  }

  calculateEllipsis() {
    const { linesMax } = this.props;
    const { calculatedText } = this.state;
    const text = this.getText();

    if (linesMax === 0) {
      if (text !== calculatedText) {
        this.setState({
          calculatedText: text,
          isOverflown: false,
        });
      }
      return;
    }
    if (calculatedText === text && !this.isOverflown()) {
      if (this.state.isOverflown) {
        this.setState({ isOverflown: false });
      }
      return;
    }

    this.ellipsisEl.style.display = 'inline';
    // Safe guard
    let maxIter = text.length;
    let newCalculatedText = text;
    for (let left = 0, right = text.length; left !== right && --maxIter > 0; ) {
      let medium = Math.floor((left + right) / 2);
      medium = this.findClosestSpace(text, medium);
      if (medium > right) {
        --right;
      } else if (medium < left) {
        ++left;
      }
      newCalculatedText = text.substr(0, medium);
      this.textEl.innerHTML = newCalculatedText;
      if (this.isOverflown()) {
        right = medium;
      } else {
        left = medium;
      }
    }
    if (newCalculatedText !== calculatedText) {
      this.setState({
        calculatedText: newCalculatedText,
        isOverflown: true,
      });
    }
  }

  render() {
    const { ellipsis, tag } = this.props;
    const { calculatedText, isOverflown } = this.state;
    const Tag = tag;
    const displayText = calculatedText ? calculatedText : this.getText();
    const isHtml = this.isHtml();

    return (
      <div ref={(el) => (this.el = el)}>
        {isHtml && (
          <Tag
            dangerouslySetInnerHTML={{ __html: calculatedText }}
            ref={(el) => (this.textEl = el)}
          />
        )}
        {!isHtml && <Tag ref={(el) => (this.textEl = el)}>{displayText}</Tag>}
        <span
          ref={(el) => (this.ellipsisEl = el)}
          style={{ display: isOverflown ? 'inline' : 'none' }}
        >
          {ellipsis}
        </span>
      </div>
    );
  }
}
export default Ellipsis;
