'use client';
import React, { useMemo } from 'react';

import { usePathname, useRouter } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';

import { SLIDESHOW } from '@bloom/library/components/Gallery/constants';
import { GalleryGridTypeEnum } from '@bloom/library/components/Gallery/types';
import { useAuthentication } from '@bloom/library/components/hooks/useAuthentication';
import { useMe } from '@bloom/library/components/hooks/useMe';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import CalendarIcon from '@bloom/library/components/Icon/Calendar';
import GuageIcon from '@bloom/library/components/Icon/Guage';
import OffIcon from '@bloom/library/components/Icon/Off';
import UserIcon from '@bloom/library/components/Icon/User';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { getBusinessNameFromAccount } from '@bloom/library/utils/misc';

import FooterWithContent from '@bloom/portal/components/Footer/FooterWithContent';
import { usePortfolio } from '@bloom/portal/components/hooks/usePortfolio';
import TopMenu from '@bloom/portal/components/Nav/TopMenu';

import commonStyles from './Common.module.scss';
import style from './PhotographerProfile.module.scss';
import AboutPage from './PhotographerProfile/About';
import HomePage from './PhotographerProfile/Home';
import PortfolioPage from './Portfolio';

const PhotographerProfile: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [searchParams] = useSearchParams();

  const { logout } = useAuthentication();
  const { me } = useMe();
  const { publicAccount } = usePublicAccountByCustomUrl();

  const { data: portfolio } = usePortfolio(publicAccount?.user.id);

  const heading = getBusinessNameFromAccount(publicAccount);

  const {
    footerText,
    slideshowGallery: { gridType },
  } = publicAccount || { slideshowGallery: { gridType: GalleryGridTypeEnum.ROWS } };

  const [, { showQuoteRequestModal }] = useQuoteRequest();

  // Set Portfolio dropdown options
  const pages = useMemo(() => {
    const { albumArrangement, albums } = portfolio;
    const sortedAlbums = albums
      .slice()
      .sort((a, b) => albumArrangement[a.id] - albumArrangement[b.id]);
    return [
      {
        Comp: HomePage,
        // Is shown in desktop menu
        isDesktop: true,
        // Is shown in mobile menu
        isMobile: true,
        name: 'Home',
        path: '/',
      },
      {
        Comp: PortfolioPage,
        // This link is rendered as a dropdown.
        dropdownItems: sortedAlbums.map((album) => ({
          caption: album.title,
          onClick: () => router.push(`/portfolio?specialty=${encodeURIComponent(album.title)}`),
        })),
        isDesktop: true,
        isMobile: true,
        name: 'Gallery',
        path: '/portfolio',
      },
      {
        Comp: AboutPage,
        isDesktop: true,
        isMobile: true,
        name: 'About',
        path: '/about',
      },
    ];
  }, [router.push, portfolio]);

  function handleLoginClick() {
    router.push('/login');
  }

  let { buttonText } = publicAccount?.cta || {};
  // button text might be undefined by default,
  // or empty string if a customer cleaned up the field
  buttonText = buttonText || 'Get Started';

  const menuItems = useMemo(() => {
    return [
      me?.defaultAccountId
        ? {
            callback: () => {
              // Do not use router push to redirect to the dashboard.
              // We need to reload the app to reset the state and remove unwanted
              // elements such as the messenger widget on the dashboard page.
              window.location.href = `${window.location.origin}/portal/dashboard`;
            },
            caption: 'Dashboard',
            icon: <GuageIcon width={14} />,
          }
        : {
            callback: () => {
              window.location.href = `${window.location.origin}/portal/projects`;
            },
            caption: 'Bookings',
            icon: <CalendarIcon width={14} />,
          },
      {
        callback: () => {
          window.location.href = `${window.location.origin}/portal/user-profile`;
        },
        caption: 'User Profile',
        icon: <UserIcon width={14} />,
      },
      {
        callback: logout,
        caption: 'Log out',
        icon: <OffIcon width={12} />,
      },
    ];
  }, [logout, me?.defaultAccountId]);

  if (publicAccount) {
    return (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: `
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/RobotoCondensed-Regular.woff2);
}

@font-face {
  font-family: 'Roboto Condensed Bold';
  font-style: normal;
  font-weight: 400;
  src: url(https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/RobotoCondensed-Bold.woff2);
}`,
          }}
        />
        <div className={style.container}>
          <TopMenu
            ctaButtonText={buttonText}
            items={pages}
            logo={publicAccount.logo}
            logoDark={publicAccount.logoDark}
            onLoginClick={handleLoginClick}
            onRequestQuoteClick={showQuoteRequestModal}
            pathname={pathname}
            profile={publicAccount}
            searchParams={searchParams}
            template="minimalist"
            textLogo={heading}
            userMenuItems={menuItems}
          />
          <div
            className={twMerge(
              commonStyles.content,
              style.page,
              pathname === '/' && gridType === SLIDESHOW ? style.pageSlideshow : ''
            )}
          >
            {children}
          </div>
          <div className={twMerge(commonStyles.content, style.footerWrap)}>
            <FooterWithContent
              address={publicAccount.location.address}
              className={style.footer}
              currentPath={pathname}
              footerText={footerText}
              isBrandingRemoved={publicAccount.isBrandingRemoved}
              phone={publicAccount.phone}
              socialLinks={publicAccount.socialMediaLinks}
              textLogo={heading}
            />
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default PhotographerProfile;
