__turbopack_context__.v({
  "avatar": "LeftMenu-module-scss-module__09hP4G__avatar",
  "container": "LeftMenu-module-scss-module__09hP4G__container",
  "desktopMenu": "LeftMenu-module-scss-module__09hP4G__desktopMenu",
  "hamburgerButton": "LeftMenu-module-scss-module__09hP4G__hamburgerButton",
  "hr": "LeftMenu-module-scss-module__09hP4G__hr",
  "lastItem": "LeftMenu-module-scss-module__09hP4G__lastItem",
  "menuAbsolutePosition": "LeftMenu-module-scss-module__09hP4G__menuAbsolutePosition",
  "menuPopup": "LeftMenu-module-scss-module__09hP4G__menuPopup",
  "mobileMenu": "LeftMenu-module-scss-module__09hP4G__mobileMenu",
  "mobileMenuWrapper": "LeftMenu-module-scss-module__09hP4G__mobileMenuWrapper",
  "mobileNav": "LeftMenu-module-scss-module__09hP4G__mobileNav",
  "moreItemArrow": "LeftMenu-module-scss-module__09hP4G__moreItemArrow",
  "navContainer": "LeftMenu-module-scss-module__09hP4G__navContainer",
  "portalButton": "LeftMenu-module-scss-module__09hP4G__portalButton",
  "portfolioMenu": "LeftMenu-module-scss-module__09hP4G__portfolioMenu",
  "profolioItem": "LeftMenu-module-scss-module__09hP4G__profolioItem",
  "selected": "LeftMenu-module-scss-module__09hP4G__selected",
  "textLogo": "LeftMenu-module-scss-module__09hP4G__textLogo",
  "userMenu": "LeftMenu-module-scss-module__09hP4G__userMenu",
});
