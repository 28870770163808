'use client';

import React from 'react';

import { usePathname } from 'next/navigation';

import { CSSTransition } from '@bloom/ui/components/CSSTransition';

import PhotographerProfile from './PhotographerProfile';
import Portfolio from './Portfolio';

import style from './Container.module.scss';

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const pathname = usePathname();
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.woff2);
}

@font-face {
  font-family: 'Open Sans Medium';
  font-style: normal;
  font-weight: 400;
  src: url(https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Medium.woff2);
}`,
        }}
      />

      <CSSTransition active={pathname.includes('portfolio')}>
        <div>
          <div className={style.backdrop} />
          <Portfolio />
        </div>
      </CSSTransition>

      {pathname.includes('portfolio') ? null : (
        <PhotographerProfile>{children}</PhotographerProfile>
      )}
    </>
  );
};

export default Container;
