import React from 'react';

function Off({ width = 13, className = '', color = '#000' }) {
  return (
    <svg width={width} height={(width * 13) / 12} className={className} viewBox="0 0 12 13">
      <path
        fill={color}
        d="M9.60461538,2.20357712 C9.16246154,1.87207949 8.53569231,1.96122171 8.20430769,2.40228998 C7.872,2.84335826 7.96061538,3.4701395 8.40276923,3.8025657 C9.41723077,4.56584596 9.99969231,5.73212334 9.99969231,7.00054285 C9.99969231,9.20588424 8.20523077,11.0008 6,11.0008 C3.79476923,11.0008 2.00030769,9.20588424 2.00030769,7.00054285 C2.00030769,5.73026621 2.58369231,4.56398883 3.60092308,3.80070857 C4.04215385,3.46828237 4.13169231,2.84150113 3.80030769,2.39950429 C3.46707692,1.95843601 2.84123077,1.86929379 2.4,2.20079142 C0.875076923,3.34571182 0,5.09605646 0,7.00054285 C0,10.3090192 2.69169231,13 6,13 C9.30830769,13 12,10.3090192 12,7.00054285 C12,5.09698502 11.1267692,3.34942608 9.60461538,2.20357712 Z M6.00009231,6.00038571 C6.55209231,6.00038571 6.99978462,5.55281748 6.99978462,5.00032143 L6.99978462,1.00006429 C6.99978462,0.448496796 6.55209231,0 6.00009231,0 C5.44809231,0 5.0004,0.448496796 5.0004,1.00006429 L5.0004,5.00032143 C5.0004,5.55281748 5.44809231,6.00038571 6.00009231,6.00038571 Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Off;
