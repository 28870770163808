import React from 'react';

function Calendar({ width = 14, className = '', color = '#000' }) {
  return (
    <svg width={width} className={className} height={width * (16 / 17)} viewBox="0 0 17 16">
      <path
        fill={color}
        d="M2,2 L1.78947368,2 L0.894736842,2 C0.400842105,2 0,2.392 0,2.875 L0,5.5 L0,7.25 L0,15.204625 C0,15.643875 0.400842105,16 0.894736842,16 L16.1052632,16 C16.5991579,16 17,15.643875 17,15.204625 L17,7.25 L17,5.5 L17,2.875 C17,2.392 16.5991579,2 16.1052632,2 L15.2105263,2 L15,2 C15,3.104 14.104,4 13,4 C11.896,4 11,3.104 11,2 L6,2 C6,3.104 5.104,4 4,4 C2.896,4 2,3.104 2,2 Z M14.5666667,7 L2.43333333,7 C2.19413333,7 2,6.776 2,6.5 L2,5.5 C2,5.224 2.19413333,5 2.43333333,5 L14.5666667,5 C14.8058667,5 15,5.224 15,5.5 L15,6.5 C15,6.776 14.8058667,7 14.5666667,7 Z M4,3 C3.448,3 3,2.552 3,2 L3,1 C3,0.448 3.448,0 4,0 C4.552,0 5,0.448 5,1 L5,2 C5,2.552 4.552,3 4,3 Z M13,3 C12.448,3 12,2.552 12,2 L12,1 C12,0.448 12.448,0 13,0 C13.552,0 14,0.448 14,1 L14,2 C14,2.552 13.552,3 13,3 Z"
        id="icon"
        stroke="none"
        fillRule="evenodd"
      />
    </svg>
  );
}
export default Calendar;
