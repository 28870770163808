'use client';

import React, { useEffect, useMemo } from 'react';

import { usePathname } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';

import { SLIDESHOW } from '@bloom/library/components/Gallery/constants';
import { useAuthentication } from '@bloom/library/components/hooks/useAuthentication';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useMe } from '@bloom/library/components/hooks/useMe';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import CalendarIcon from '@bloom/library/components/Icon/Calendar';
import GuageIcon from '@bloom/library/components/Icon/Guage';
import OffIcon from '@bloom/library/components/Icon/Off';
import UserIcon from '@bloom/library/components/Icon/User';

import FooterWithContent from '@bloom/portal/components/Footer/FooterWithContent';
import { usePortfolio } from '@bloom/portal/components/hooks/usePortfolio';
import LeftMenu from '@bloom/portal/components/Nav/LeftMenu';

import commonStyles from './Common.module.scss';
import style from './PhotographerProfile.module.scss';

const PhotographerProfile: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const pathname = usePathname();
  const [searchParams] = useSearchParams();

  const { logout } = useAuthentication();

  const { me } = useMe();
  const { publicAccount } = usePublicAccountByCustomUrl();

  const gridType = publicAccount?.slideshowGallery?.gridType;

  const userId = publicAccount?.user.id;
  const { status } = usePortfolio(userId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const clientMenuItems = useMemo(
    () => [
      {
        callback: () => {
          // Do not use router push to redirect to the dashboard.
          // We need to reload the app to reset the state and remove unwanted
          // elements such as the messenger widget on the dashboard page.
          window.location.href = `${window.location.origin}/portal/projects`;
        },
        caption: 'Bookings',
        icon: <CalendarIcon width={14} />,
      },
      {
        callback: () => {
          window.location.href = `${window.location.origin}/portal/user-profile`;
        },
        caption: 'User Profile',
        icon: <UserIcon width={14} />,
      },
      {
        callback: logout,
        caption: 'Log out',
        icon: <OffIcon width={12} />,
      },
    ],
    [logout]
  );

  const photographerMenuItems = useMemo(
    () => [
      {
        callback: () => {
          // Do not use router push to redirect to the dashboard.
          // We need to reload the app to reset the state and remove unwanted
          // elements such as the messenger widget on the dashboard page.
          window.location.href = `${window.location.origin}/portal/dashboard`;
        },
        caption: 'Dashboard',
        icon: <GuageIcon width={14} />,
      },
      {
        callback: () => {
          window.location.href = `${window.location.origin}/portal/user-profile`;
        },
        caption: 'User Profile',
        icon: <UserIcon width={14} />,
      },
      {
        callback: logout,
        caption: 'Log out',
        icon: <OffIcon width={12} />,
      },
    ],
    [logout]
  );

  const { data: portfolio } = usePortfolio(userId);

  const pages = useMemo(() => {
    const { albumArrangement, albums } = portfolio;
    const sortedAlbums = albums
      .slice()
      .sort((a, b) => albumArrangement[a.id] - albumArrangement[b.id]);
    return [
      {
        name: 'Home',
        path: '/',
      },
      {
        dropdownItems: sortedAlbums.map((album) => ({
          caption: album.title,
          to: {
            pathname: '/portfolio',
            search: `?specialty=${encodeURIComponent(album.title)}`,
          },
        })),
        name: 'Gallery',
        path: '/portfolio',
      },
      {
        name: 'About',
        path: '/about',
      },
      {
        name: 'Login',
        path: '/login',
      },
    ];
  }, [portfolio]);

  const menuItems = me?.defaultAccountId ? photographerMenuItems : clientMenuItems;

  const isHomeSlideshow = pathname === '/' && gridType === SLIDESHOW;

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
@font-face {
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
src: url(https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/Raleway-Regular.woff2);
}

@font-face {
font-family: 'Raleway Medium';
font-style: normal;
font-weight: 400;
src: url(https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/Raleway-SemiBold.woff2);
}`,
        }}
      />
      <div className={style.container}>
        <div
          className={twMerge(style.contentWrap, commonStyles.content, 'flex flex-col xl:flex-row')}
        >
          <LeftMenu
            items={pages}
            pathname={pathname}
            searchParams={searchParams}
            userMenuItems={menuItems}
          />
          <div className={twMerge(style.page, isHomeSlideshow ? style.pageSlideshow : '')}>
            {children}
          </div>
        </div>
        <div
          className={twMerge(
            style.footerWrap,
            !isHomeSlideshow && status === AsyncStatusEnum.PENDING ? style.footerFade : ''
          )}
        >
          <FooterWithContent className={style.footer} />
        </div>
      </div>
    </>
  );
};

export default PhotographerProfile;
