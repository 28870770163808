import React, { useEffect } from 'react';

import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '@bloom/ui/components/Icons/Check';
import { doNothing } from '@bloom/ui/utils/empty-value';

import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './MenuItem.module.scss';

interface IProps {
  caption?: React.ReactNode;
  className?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick: (e: React.MouseEvent) => void;
  onClose?: VoidFunction;
  onMouseDown?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
}

const MenuItem: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const {
    caption = '',
    children,
    className,
    isChecked,
    isDisabled,
    isSelected,
    onClick = doNothing,
    onClose,
    onMouseDown = doNothing,
    onMouseEnter = doNothing,
    onMouseLeave = doNothing,
  } = props;

  const customColor = useCustomColor();

  useEffect(() => {
    if (!['production', 'test'].includes(process.env.NODE_ENV)) {
      console.error('🚨 Dropdown/MenuItem is deprecated!🚨');
    }
  }, []);

  function handleItemClick(e) {
    // If you have a nested structure, i.e. popup inside another popup,
    // this prevents outer popup from closing when inner popup is closed.
    e.nativeEvent.stopImmediatePropagation();

    if (isDisabled) {
      return;
    }

    onClick(e);

    // onClose handler may be injected by wrapper component,
    // for example, DropdownSelect.
    if (typeof onClose === 'function') {
      setTimeout(() => onClose());
    }
  }

  const item = caption || children;

  return (
    <div
      className={twMerge(
        style.item,
        style.hoverable,
        className,
        isChecked ? style.checked : '',
        isSelected ? style.selected : '',
        isDisabled ? style.disabled : ''
      )}
      onClick={handleItemClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ ...(props.style || {}) }}
    >
      <span
        className={style.text}
        dangerouslySetInnerHTML={
          typeof item === 'string' ? { __html: escapeHTML(item) } : undefined
        }
      >
        {typeof item !== 'string' ? item : undefined}
      </span>
      {isChecked && <CheckIcon className={style.icon} color={customColor} />}
    </div>
  );
};

export default MenuItem;
