'use client';
import { Component } from 'react';

import Link from 'next/link';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { CSSTransition } from '@bloom/ui/components/CSSTransition';

import HamburgerButton from '@bloom/library/components/Button/HamburgerButton';
import CompanyLogo from '@bloom/library/components/CompanyLogo';
import withCustomColor from '@bloom/library/components/HOC/withCustomColor';
import { useMe } from '@bloom/library/components/hooks/useMe';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import TriangleIcon from '@bloom/library/components/Icon/Triangle';
import AvatarDropdown from '@bloom/library/components/Nav/AvatarDropdown';
import Ellipsis from '@bloom/library/components/Text/Ellipsis';
import { getCookie } from '@bloom/library/utils/browser';
import { getBusinessNameFromAccount } from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

import { FormButtonWrapper } from '@bloom/portal/containers/public/pages/templates/nordicAir/FormButton';

import style from './LeftMenu.module.scss';

const logoMaxSize = {
  height: 200,
  width: 200,
};

interface IProps {
  customColor: string;
  items: Array<{
    dropdownItems?: Array<{ caption: string; to: { pathname: string; search: string } }>;
    name: string;
    path: string;
  }>;
  pathname: string;
  searchParams: ReadonlyURLSearchParams;
  userMenuItems: Array<{ callback: () => void; caption: string; icon: JSX.Element }>;
}

interface IState {
  isMobileMenuOpen: boolean;
  isMobileSubMenuShown: boolean;
  isMorePopupShown: boolean;
  isPortalMenuShown: boolean;
  logoSize: Record<'height' | 'width', number>;
  logoSpacingTop: number;
}

const AvatarDropdownComponent: React.FC<{
  userMenuItems: Array<{ callback: () => void; caption: string; icon: JSX.Element }>;
}> = (props) => {
  const { userMenuItems } = props;
  const { me } = useMe();

  if (me) {
    return (
      <AvatarDropdown
        className={style.avatar}
        isWelcomeShown={false}
        menuItems={userMenuItems}
        placement="bottom-start"
        user={me}
      />
    );
  }

  return null;
};

const CompanyLogoComponent: React.FC<{ height: number; width: number }> = (props) => {
  const { height, width } = props;
  const { publicAccount } = usePublicAccountByCustomUrl();

  const logo = publicAccount?.logo || publicAccount?.logoDark || '';
  const textLogo = getBusinessNameFromAccount(publicAccount);

  return logo ? (
    <CompanyLogo maxSize={{ height, width }} size="MD" src={logo} />
  ) : (
    <h1 className={style.textLogo} dangerouslySetInnerHTML={{ __html: escapeHTML(textLogo) }} />
  );
};

class LeftMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isMobileMenuOpen: false,
      isMobileSubMenuShown: false,
      isMorePopupShown: false,
      isPortalMenuShown: false,
      logoSize: { ...logoMaxSize },
      logoSpacingTop: 50 - 2,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.toggleProfileMenu(false);
  }

  componentDidUpdate() {
    this.handleScroll();
    this.toggleProfileMenu();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  matchSearch = (itemTo: { pathname: string; search: string }) => {
    const { pathname, searchParams } = this.props;
    const search = searchParams.toString();

    return `${itemTo.pathname}${itemTo.search}` === `${pathname}${search ? `?${search}` : ''}`;
  };

  toggleProfileMenu = (isAnimated = true) => {
    const { pathname } = this.props;
    const portfolioPath = '/portfolio';

    if (
      pathname === portfolioPath &&
      this.portfolioMenu.offsetHeight === 0 &&
      this.portfolioMenu.scrollHeight !== 0
    ) {
      // expand sub menu
      if (!isAnimated) {
        this.portfolioMenu.style.transition = 'none';
      }
      this.portfolioMenu.style.height = `${this.portfolioMenu.scrollHeight}px`;
      setTimeout(() => {
        // wait for animation is done
        this.portfolioMenu.style.overflow = 'visible';
      }, 300);
    } else if (pathname !== portfolioPath && this.portfolioMenu.offsetHeight > 0) {
      // collapse sub menu
      this.portfolioMenu.style.height = 0;
      this.portfolioMenu.style.overflow = '';
      if (isAnimated) {
        this.portfolioMenu.style.transition = '';
      }
    }
  };

  toggleMobileSubMenu = (e) => {
    e.preventDefault();
    const { isMobileSubMenuShown } = this.state;
    this.setState({ isMobileSubMenuShown: !isMobileSubMenuShown });
  };

  togglePortfolioMenu = (e = {}) => {
    const { isMorePopupShown } = this.state;
    switch (e.type) {
      case 'mouseenter':
        if (!isMorePopupShown) {
          this.setState({ isMorePopupShown: true });
        }
        break;
      case 'mouseleave':
        if (isMorePopupShown) {
          this.setState({ isMorePopupShown: false });
        }
        break;
      default:
        this.setState((state) => ({ isMorePopupShown: !state.isMorePopupShown }));
    }
  };

  toggleMobileMenu = () => {
    this.setState(({ isMobileMenuOpen }) => ({ isMobileMenuOpen: !isMobileMenuOpen }));
  };

  togglePortalMenu = (e) => {
    e.preventDefault();
    this.setState(({ isPortalMenuShown }) => ({ isPortalMenuShown: !isPortalMenuShown }));
  };

  handleDropdownItemClick = (onClick) => {
    this.setState({ isMobileMenuOpen: false });
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  handleScroll = () => {
    if (window.getComputedStyle(document.body).position === 'fixed') {
      // Hack:
      // prevent handling scroll on showing modal like login
      // find a better solution
      return;
    }
    const { pageYOffset } = window;

    // Logo size changes only on scrolling first 120px
    if (pageYOffset < 120) {
      const { height, width } = logoMaxSize;
      const ratio = width / height;

      const scrollDiff = pageYOffset - 3 > 0 ? pageYOffset - 3 : 0;

      const newHeight = Math.max(height * 0.8, height - Math.min(scrollDiff, 200) * 0.4);
      const newWidth = newHeight * ratio;

      if (this.state.logoSize.height !== newHeight && this.state.logoSize.width !== newWidth) {
        this.setState({
          logoSize: {
            height: newHeight,
            width: newWidth,
          },
          logoSpacingTop: Math.max(30, 42 - Math.min(scrollDiff, 200) * 0.2),
        });
      }
    }
  };

  handleUserMobileMenuClick = (cb, e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({ isMobileMenuOpen: false });

    if (typeof cb === 'function') {
      setTimeout(cb, 300);
    }
  };

  renderMoreMenu = (items, startIndex) => {
    const { isMorePopupShown } = this.state;
    const { customColor } = this.props;

    if (items.length > startIndex) {
      return (
        <li onMouseEnter={this.togglePortfolioMenu} onMouseLeave={this.togglePortfolioMenu}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={style.profolioItem}>
            More
            <TriangleIcon className={style.moreItemArrow} rotate={isMorePopupShown ? 90 : 180} />
          </a>
          <CSSTransition active={isMorePopupShown}>
            <ul className={style.menuPopup} style={{ maxHeight: `${5 * 44}px` }}>
              {items.slice(startIndex, items.length).map((item, index) => (
                <li
                  className="inline-flex cursor-pointer items-center gap-2 py-6 text-sm"
                  key={index}
                >
                  <Link
                    className={twMerge(style.link, this.matchSearch(item.to) ? style.selected : '')}
                    href={item.to}
                    onClick={this.togglePortfolioMenu}
                    style={{ color: this.matchSearch(item.to) ? customColor : '' }}
                  >
                    <Ellipsis
                      dangerouslySetInnerHTML={{ __html: escapeHTML(item.caption) }}
                      linesMax={2}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </CSSTransition>
        </li>
      );
    }
    return null;
  };

  renderMenuItem = (item) => {
    const { customColor, pathname, searchParams } = this.props;
    const search = searchParams.toString();
    return (
      <Link
        className={twMerge(
          'inline-flex cursor-pointer items-center gap-2 py-6 text-sm',
          item.path === pathname ? 'text-black' : 'text-grey hover:text-black'
        )}
        data-testid={`${item.path.replace('/', '')}-side-menu-link`}
        href={`${item.path}${search ? `?${search}` : ''}`}
        onClick={item.onClick}
      >
        <i
          className={twMerge('h-0.5 w-2.5', item.path !== pathname ? 'hidden' : '')}
          style={{ backgroundColor: customColor }}
        />
        {item.name}
      </Link>
    );
  };

  renderPortfolioMenu = (page) => {
    const { customColor } = this.props;
    const { dropdownItems = [], maxInlineVisibleItems = 3 } = page;
    return (
      <ul
        className={style.portfolioMenu}
        ref={(node) => {
          this.portfolioMenu = node;
        }}
      >
        {dropdownItems.slice(0, maxInlineVisibleItems).map((item) => (
          <li key={item.caption}>
            <Link
              className={style.profolioItem}
              href={item.to}
              style={{
                color: this.matchSearch(item.to) ? customColor : '',
                fontWeight: this.matchSearch(item.to) ? 600 : 'normal',
              }}
            >
              <Ellipsis
                dangerouslySetInnerHTML={{ __html: escapeHTML(item.caption) }}
                linesMax={2}
              />
            </Link>
          </li>
        ))}
        {this.renderMoreMenu(dropdownItems, maxInlineVisibleItems)}
      </ul>
    );
  };

  renderDesktopMenu = () => {
    const { items, userMenuItems } = this.props;
    const isLoggedIn = getCookie('bloom_token');

    return (
      <nav className={style.navContainer}>
        {isLoggedIn && <AvatarDropdownComponent userMenuItems={userMenuItems} />}
        <ul>
          {items.map((item) =>
            item.name === 'Login' && isLoggedIn ? null : (
              <li key={item.name}>
                {this.renderMenuItem(item)}

                {item.name === 'Gallery' && this.renderPortfolioMenu(item)}
              </li>
            )
          )}
          <li>
            <FormButtonWrapper />
          </li>
        </ul>
      </nav>
    );
  };

  renderMobileMenu = () => {
    const { customColor, items, pathname, userMenuItems } = this.props;
    const { isMobileSubMenuShown, isPortalMenuShown } = this.state;
    const isLoggedIn = getCookie('bloom_token');
    let dropdownLinks = [];

    return (
      <div className={style.mobileMenuWrapper} onClick={this.handleOutsideClick}>
        <nav className={style.mobileNav}>
          <ul>
            {items.map(({ dropdownItems, name, onClick, path }, index) => {
              if (name === 'Login' && isLoggedIn) {
                return null;
              }

              if (dropdownItems) {
                dropdownLinks = dropdownItems.map(({ caption, onClickCb, to }) => (
                  <li key={caption} onClick={this.handleDropdownItemClick.bind(null, onClickCb)}>
                    <Link
                      className={style.profolioItem}
                      href={to}
                      style={{
                        color: this.matchSearch(to) ? customColor : '',
                        fontWeight: this.matchSearch(to) ? 600 : '',
                      }}
                    >
                      <Ellipsis
                        dangerouslySetInnerHTML={{ __html: escapeHTML(caption) }}
                        linesMax={2}
                      />
                    </Link>
                  </li>
                ));

                return (
                  <li key={index}>
                    <Link
                      className={twMerge(style.link, path === pathname ? style.isActive : '')}
                      href={path}
                      onClick={this.toggleMobileSubMenu}
                    >
                      {name}
                    </Link>
                    <TriangleIcon
                      className={style.moreItemArrow}
                      rotate={isMobileSubMenuShown ? null : 180}
                    />
                    {isMobileSubMenuShown && (
                      <ul className={style.mobileSubMenu}>{dropdownLinks}</ul>
                    )}
                  </li>
                );
              }

              return (
                <li key={index} onClick={this.handleUserMobileMenuClick.bind(null, onClick)}>
                  <Link className={path === pathname ? style.isActive : ''} href={path}>
                    {name}
                  </Link>
                  {dropdownItems && <ul>{dropdownLinks}</ul>}
                </li>
              );
            })}

            {isLoggedIn && (
              <li className={style.lastItem}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={twMerge(style.button, style.portalButton)}
                  href="#"
                  onClick={this.togglePortalMenu}
                >
                  My Portal{' '}
                  <TriangleIcon
                    className={style.moreItemArrow}
                    rotate={isPortalMenuShown ? null : 180}
                  />
                </a>
                {isPortalMenuShown && (
                  <ul className={style.userMenu}>
                    {userMenuItems.map((item, index) => (
                      <li key={index}>
                        <span onClick={this.handleUserMobileMenuClick.bind(null, item.callback)}>
                          {item.caption}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  };

  render() {
    const { pathname } = this.props;
    const { isMobileMenuOpen } = this.state;

    return (
      <div className={style.container}>
        <div className={style.desktopMenu}>
          <Link href="/">
            <CompanyLogoComponent {...this.state.logoSize} />
          </Link>
          <hr className={style.hr} style={{ marginTop: `${this.state.logoSpacingTop}px` }} />
          {this.renderDesktopMenu()}
        </div>

        <div className={style.mobileMenu}>
          <HamburgerButton
            className={style.hamburgerButton}
            isActive={isMobileMenuOpen}
            onClick={this.toggleMobileMenu}
          />
          <CSSTransition active={isMobileMenuOpen}>{this.renderMobileMenu()}</CSSTransition>

          {pathname === '/' ? <CompanyLogoComponent {...this.state.logoSize} /> : null}
        </div>
      </div>
    );
  }
}

export default withCustomColor(LeftMenu);
