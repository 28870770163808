import React from 'react';

import { twMerge } from 'tailwind-merge';

import Button from './Button';

import style from './HamburgerButton.module.css';

interface Props extends React.ComponentProps<typeof Button> {
  activeClassName?: string;
  isActive?: boolean;
  onClick: () => void;
  theme?: 'light' | 'dark';
}

function HamburgerButton({
  activeClassName = '',
  className = '',
  isActive = false,
  onClick,
  theme = 'light',
  ...restProps
}: Props) {
  return (
    <Button
      className={twMerge(
        style.container,
        'relative border-none bg-transparent p-0 outline-hidden',
        className,
        isActive ? style.active : '',
        isActive ? activeClassName : ''
      )}
      onClick={onClick}
      {...restProps}
    >
      <span
        className={twMerge(
          'left-1',
          theme === 'light' || isActive ? 'before:bg-black after:bg-black' : '',
          theme === 'light' && !isActive ? 'bg-black' : '',

          theme === 'dark' && !isActive ? 'bg-white before:bg-white after:bg-white' : ''
        )}
      />
    </Button>
  );
}

export default HamburgerButton;
