__turbopack_context__.v({
  "container": "FooterWithContent-module-scss-module__pjvBiG__container",
  "copyright": "FooterWithContent-module-scss-module__pjvBiG__copyright",
  "copyrightYear": "FooterWithContent-module-scss-module__pjvBiG__copyrightYear",
  "footer": "FooterWithContent-module-scss-module__pjvBiG__footer",
  "footerMuted": "FooterWithContent-module-scss-module__pjvBiG__footerMuted",
  "footerSocialLinks": "FooterWithContent-module-scss-module__pjvBiG__footerSocialLinks",
  "hideMobile": "FooterWithContent-module-scss-module__pjvBiG__hideMobile",
  "legal": "FooterWithContent-module-scss-module__pjvBiG__legal",
  "mainSection": "FooterWithContent-module-scss-module__pjvBiG__mainSection",
  "nordicAirTemplate": "FooterWithContent-module-scss-module__pjvBiG__nordicAirTemplate",
  "phone": "FooterWithContent-module-scss-module__pjvBiG__phone",
  "secondarySection": "FooterWithContent-module-scss-module__pjvBiG__secondarySection",
  "sectionHeader": "FooterWithContent-module-scss-module__pjvBiG__sectionHeader",
  "sectionText": "FooterWithContent-module-scss-module__pjvBiG__sectionText",
  "socialLinksTwoRows": "FooterWithContent-module-scss-module__pjvBiG__socialLinksTwoRows",
  "socialSectionHeader": "FooterWithContent-module-scss-module__pjvBiG__socialSectionHeader",
  "studioContacts": "FooterWithContent-module-scss-module__pjvBiG__studioContacts",
});
