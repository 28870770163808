__turbopack_context__.v({
  "content": "Footer-module-scss-module__EODrNG__content",
  "copyright": "Footer-module-scss-module__EODrNG__copyright",
  "delimiter": "Footer-module-scss-module__EODrNG__delimiter",
  "footer": "Footer-module-scss-module__EODrNG__footer",
  "legal": "Footer-module-scss-module__EODrNG__legal",
  "linkContainer": "Footer-module-scss-module__EODrNG__linkContainer",
  "logo": "Footer-module-scss-module__EODrNG__logo",
  "social": "Footer-module-scss-module__EODrNG__social",
});
