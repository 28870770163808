import React, { Component } from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';

import style from './StyledLink.module.scss';
import { twMerge } from 'tailwind-merge';

export default class StyledLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    isActive: false,
  };

  render() {
    const { isActive, className, href } = this.props;
    const cssClasses = twMerge(
      style.styledLink,
      // Use global class name in order to be able
      // to override it.
      isActive ? style.styledLinkActive : '',
      className
    );
    return (
      <span className={cssClasses}>
        <Link href={href} />
        <i className="linkUnderline"></i>
      </span>
    );
  }
}
