import React from 'react';

function User({ width = 14, className = '', color = '#000' }) {
  return (
    <svg width={width} height={width * (13.12 / 14)} className={className} viewBox="0 0 14 13.12">
      <path
        fill={color}
        d="M296,419c-2.2,0-3.5,1.79-3.5,4s1.3,4.49,3.5,4.49,3.5-2.28,3.5-4.49S298.2,419,296,419Zm7,11.68c-0.1-1.42-3.19-2.56-7-2.56s-6.9,1.14-7,2.56h0v1.44h14v-1.44h0Z"
        transform="translate(-289 -419)"
      />
    </svg>
  );
}

export default User;
