__turbopack_context__.v({
  "active": "TopHalfMenu-module-scss-module__6WwHtG__active",
  "avatar": "TopHalfMenu-module-scss-module__6WwHtG__avatar",
  "bottomBar": "TopHalfMenu-module-scss-module__6WwHtG__bottomBar",
  "buttons": "TopHalfMenu-module-scss-module__6WwHtG__buttons",
  "desktopMenu": "TopHalfMenu-module-scss-module__6WwHtG__desktopMenu",
  "detached": "TopHalfMenu-module-scss-module__6WwHtG__detached",
  "dropdown": "TopHalfMenu-module-scss-module__6WwHtG__dropdown",
  "dropdownMenu": "TopHalfMenu-module-scss-module__6WwHtG__dropdownMenu",
  "hamburgerButton": "TopHalfMenu-module-scss-module__6WwHtG__hamburgerButton",
  "lastItem": "TopHalfMenu-module-scss-module__6WwHtG__lastItem",
  "link": "TopHalfMenu-module-scss-module__6WwHtG__link",
  "location": "TopHalfMenu-module-scss-module__6WwHtG__location",
  "login": "TopHalfMenu-module-scss-module__6WwHtG__login",
  "logoContainer": "TopHalfMenu-module-scss-module__6WwHtG__logoContainer",
  "menuContainer": "TopHalfMenu-module-scss-module__6WwHtG__menuContainer",
  "middleBar": "TopHalfMenu-module-scss-module__6WwHtG__middleBar",
  "mobileMenu": "TopHalfMenu-module-scss-module__6WwHtG__mobileMenu",
  "mobileMenuWrapper": "TopHalfMenu-module-scss-module__6WwHtG__mobileMenuWrapper",
  "popup": "TopHalfMenu-module-scss-module__6WwHtG__popup",
  "portalButton": "TopHalfMenu-module-scss-module__6WwHtG__portalButton",
  "selected": "TopHalfMenu-module-scss-module__6WwHtG__selected",
  "topBar": "TopHalfMenu-module-scss-module__6WwHtG__topBar",
  "userLogo": "TopHalfMenu-module-scss-module__6WwHtG__userLogo",
  "userLogoDark": "TopHalfMenu-module-scss-module__6WwHtG__userLogoDark",
  "userMenu": "TopHalfMenu-module-scss-module__6WwHtG__userMenu",
});
