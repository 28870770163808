'use client';
import { Component } from 'react';

import Link from 'next/link';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { PublicAccountResponse } from '@bloom/codegen/models/PublicAccountResponse';

import { CSSTransition } from '@bloom/ui/components/CSSTransition';
import { ChevronIcon } from '@bloom/ui/components/Icons/Chevron';

import Button from '@bloom/library/components/Button/Button';
import SecondaryButton from '@bloom/library/components/Button/SecondaryButton';
import CompanyLogo from '@bloom/library/components/CompanyLogo';
import {
  DropdownTrigger,
  DropdownMenu,
  DropdownMenuItem,
  Dropdown,
} from '@bloom/library/components/Floating/Dropdown';
import { SLIDESHOW } from '@bloom/library/components/Gallery/constants';
import withCustomColor from '@bloom/library/components/HOC/withCustomColor';
import { useMe } from '@bloom/library/components/hooks/useMe';
import TriangleIcon from '@bloom/library/components/Icon/Triangle';
import AvatarDropdown from '@bloom/library/components/Nav/AvatarDropdown';
import { getCookie } from '@bloom/library/utils/browser';
import { escapeHTML } from '@bloom/library/utils/string';

import { FormButtonWrapper } from '@bloom/portal/containers/public/pages/templates/minimalist/FormButton';

import style from './TopMenu.module.scss';

// in ms
const animationDuration = 600;

interface IProps {
  customColor: string;
  items: unknown[];
  logo?: string;
  logoClass?: string;
  logoDark?: string;
  onLoginClick: () => void;
  pathname: string;
  profile: PublicAccountResponse;
  searchParams: ReadonlyURLSearchParams;
  template?: string;
  textLogo?: string;
  userMenuItems: unknown[];
}

interface IState {
  isAninationRunning: boolean;
  isDetached: boolean;
  isMobileMenuOpen: boolean;
  isMobileSubMenuShown: boolean;
  isPortalMenuShown: boolean;
}

const AvatarDropdownWrapper: React.FC<{ userMenuItems: Array<unknown> }> = (props) => {
  const { userMenuItems } = props;
  const { me } = useMe();

  if (me) {
    return (
      <AvatarDropdown
        className={style.avatar}
        isWelcomeShown={false}
        menuItems={userMenuItems}
        placement="bottom-end"
        user={me}
      />
    );
  }

  return null;
};

class TopMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isAninationRunning: false,
      isDetached: props.pathname === '/' && props.profile?.slideshowGallery?.gridType === SLIDESHOW,
      isMobileMenuOpen: false,
      isMobileSubMenuShown: false,
      isPortalMenuShown: false,
    };
  }

  componentDidMount() {
    // Note that using _isMounted is anti-pattern.
    // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
    // I use it, because I haven't found a better solution.
    this._isMounted = true;

    if (this.state.isDetached && this.props.template === 'minimalist') {
      return false;
    }
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('load', this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {
    const { pathname, template } = this.props;
    if (template === 'minimalist' && pathname !== nextProps.pathname) {
      const isDetached =
        nextProps.pathname === '/' && nextProps.profile?.slideshowGallery?.gridType === SLIDESHOW;

      if (isDetached !== this.state.isDetached) {
        if (isDetached) {
          window.removeEventListener('scroll', this.handleScroll);
          window.removeEventListener('load', this.handleScroll);
        } else {
          window.addEventListener('scroll', this.handleScroll);
          window.addEventListener('load', this.handleScroll);
        }
      }

      if (this.state.isDetached !== isDetached) {
        this.setState({ isDetached });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      // Don't scroll completely up if header is "deatached"
      const scrollAmmount = window.scrollY >= 5 ? 5 : 0;
      window.scrollTo(0, scrollAmmount);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('load', this.handleScroll);
  }

  toggleHamburgerMenu = () => {
    const { isAninationRunning, isMobileMenuOpen } = this.state;
    if (!isAninationRunning) {
      this.setState({
        isAninationRunning: true,
        isMobileMenuOpen: !isMobileMenuOpen,
      });
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ isAninationRunning: false });
        }
      }, animationDuration);
    }
  };

  togglePortalMenu = (e) => {
    e.preventDefault();
    const { isPortalMenuShown } = this.state;
    this.setState({ isPortalMenuShown: !isPortalMenuShown });
  };

  toggleMobileSubMenu = (e) => {
    e.preventDefault();
    const { isMobileSubMenuShown } = this.state;
    this.setState({ isMobileSubMenuShown: !isMobileSubMenuShown });
  };

  handleScroll = () => {
    const { pageYOffset } = window;
    const { isDetached } = this.state;
    if (pageYOffset < 3) {
      this.setState({ isDetached: false });
    } else if (pageYOffset >= 3 && !isDetached) {
      this.setState({ isDetached: true });
    }
  };

  handleLoginClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.onLoginClick();
    const { isMobileMenuOpen } = this.state;
    if (isMobileMenuOpen) {
      setTimeout(() => this.setState({ isMobileMenuOpen: false }), 300);
    }
  };

  handleUserMobileMenuClick = (cb) => {
    this.setState({ isMobileMenuOpen: false });
    setTimeout(cb, 300);
  };

  handleOutsideClick = (e) => {
    if (e.target === this.mobileMenuWrapper) {
      this.toggleHamburgerMenu();
    }
  };

  handleDropdownItemClick = (onClick) => {
    this.setState({ isMobileMenuOpen: false });
    onClick();
  };

  renderMobileMenu = () => {
    const { items, userMenuItems } = this.props;
    const { isMobileSubMenuShown, isPortalMenuShown } = this.state;
    const isLoggedIn = !!getCookie('bloom_token');
    let dropdownLinks = [];

    return (
      <div
        className={style.mobileMenuWrapper}
        onClick={this.handleOutsideClick}
        ref={(node) => (this.mobileMenuWrapper = node)}
      >
        <nav className={style.mobileMenu}>
          <ul>
            {items.map(({ dropdownItems, isMobile, name, path }, index) => {
              if (!isMobile) {
                return null;
              }
              if (dropdownItems) {
                dropdownLinks = dropdownItems.map(({ caption, onClick }, i) => (
                  <li
                    className={style.link}
                    dangerouslySetInnerHTML={{ __html: escapeHTML(caption) }}
                    key={i}
                    onClick={this.handleDropdownItemClick.bind(null, onClick)}
                  />
                ));

                return (
                  <li key={index}>
                    <Link className={style.link} href={path} onClick={this.toggleMobileSubMenu}>
                      {name}
                    </Link>
                    <TriangleIcon rotate={isMobileSubMenuShown ? null : 180} />
                    {isMobileSubMenuShown && (
                      <ul className={style.mobileSubMenu}>{dropdownLinks}</ul>
                    )}
                  </li>
                );
              }
              return (
                <li key={index}>
                  <Link className={style.link} href={path} onClick={this.toggleHamburgerMenu}>
                    {name}
                  </Link>
                  {dropdownItems && <ul>{dropdownLinks}</ul>}
                </li>
              );
            })}
            {!isLoggedIn && (
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={style.button} href="#" onClick={this.handleLoginClick}>
                  Login
                </a>
              </li>
            )}

            {isLoggedIn && (
              <li className={style.lastItem}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={twMerge(style.button, style.portalButton)}
                  href="#"
                  onClick={this.togglePortalMenu}
                >
                  My Portal <TriangleIcon rotate={isPortalMenuShown ? null : 180} />
                </a>
                {isPortalMenuShown && (
                  <ul className={style.userMenu}>
                    {userMenuItems.map((item, index) => (
                      <li key={index}>
                        <span onClick={this.handleUserMobileMenuClick.bind(null, item.callback)}>
                          {item.caption}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  };

  render() {
    const {
      customColor,
      items,
      logo,
      logoClass,
      logoDark,
      pathname,
      searchParams,
      template,
      textLogo,
      userMenuItems,
    } = this.props;

    const { isDetached, isMobileMenuOpen } = this.state;
    const isLoggedIn = !!getCookie('bloom_token');

    return (
      <header
        className={twMerge(
          style.menuContainer,
          // This is desing requirement. The underline should be
          // visible only on one page on iPad landscape.
          isDetached ? style.detached : '',
          template === 'minimalist' ? style.minimalistTemplate : ''
        )}
        data-testid={`${template}-top-menu`}
      >
        <nav className={style.desktopMenu}>
          {items.map(({ dropdownItems, isDesktop, name, onClick, path }, index) => {
            if (!isDesktop) {
              return null;
            }
            if (dropdownItems && dropdownItems[0]) {
              return (
                <span className={style.dropdown} key={index}>
                  <Dropdown openOnHover placement="bottom-end">
                    <DropdownTrigger asChild>
                      <Button
                        className="hidden cursor-pointer items-center gap-2 whitespace-nowrap text-xs sm:inline-flex"
                        onClick={dropdownItems[0]?.onClick}
                      >
                        Gallery <ChevronIcon />
                      </Button>
                    </DropdownTrigger>

                    <DropdownMenu
                      className={twMerge(style.dropdownMenu, 'flex flex-col')}
                      data-testid="sort-dropdown-menu"
                    >
                      {dropdownItems.map(({ caption, onClick }) => (
                        <DropdownMenuItem
                          className="flex items-center justify-between text-sm"
                          key={`${caption}`}
                          onItemClick={onClick}
                        >
                          {caption}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </span>
              );
            }
            if (typeof onClick === 'function') {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className={style.link} href="#" key={index} onClick={onClick}>
                  {name}
                  <i style={{ borderColor: customColor }} />
                </a>
              );
            }

            return (
              <Link
                className={twMerge(style.link, path === pathname ? style.selected : '')}
                data-testid={`${path.replace('/', '')}-top-menu-link`}
                href={`${path}?${searchParams.toString()}`}
                key={index}
              >
                {name}
                <i style={{ borderColor: customColor }} />
              </Link>
            );
          })}
          <div className={style.buttons}>
            {!isLoggedIn && (
              <SecondaryButton className={style.login} onClick={this.handleLoginClick}>
                Login
              </SecondaryButton>
            )}

            <FormButtonWrapper />

            {isLoggedIn && <AvatarDropdownWrapper userMenuItems={userMenuItems} />}
          </div>
        </nav>
        <Link href="/">
          {logo || logoDark ? (
            <CompanyLogo
              className={twMerge(style.userLogo, logoClass)}
              isShort
              src={logo || logoDark}
            />
          ) : (
            <h1
              className={style.textLogo}
              dangerouslySetInnerHTML={{ __html: escapeHTML(textLogo) }}
            />
          )}
        </Link>
        <div
          className={twMerge(style.hamburgerButton, isMobileMenuOpen ? style.active : '')}
          onClick={this.toggleHamburgerMenu}
        >
          <span className={style.topBar} />
          <span className={style.middleBar} />
          <span className={style.bottomBar} />
        </div>
        <CSSTransition active={isMobileMenuOpen}>{this.renderMobileMenu()}</CSSTransition>
      </header>
    );
  }
}

export default withCustomColor(TopMenu);
